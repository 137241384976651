<template>
  <div>
    <DeliveryMethodInstanceDialog
      v-if="editing"
      :value="deliveryMethodInstance"
      :editing="editing"
      @closed="editing = false"
    />
    <v-list-item
      link
      :key="deliveryMethodInstance.uuid"
    >
      <v-list-item-icon @click="edit">
        <v-icon>mdi-package-variant-closed</v-icon>
      </v-list-item-icon>
      <v-list-item-content @click="edit">
        <v-list-item-title>{{ deliveryMethodInstance.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ deliveryMethodInstance.description }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-switch
          :disabled="processing"
          :loading="processing"
          v-model="deliveryMethodState"
        />
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import DeliveryMethodInstanceDialog from "./DeliveryMethodInstanceDialog"

export default {
  name: 'DeliveryMethodInstanceListItem',
  props: {
    deliveryMethodInstance: {
      type: Object,
      default: () => ({})
    },
    deliveryMethods: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DeliveryMethodInstanceDialog
  },
  data: () => ({
    processing: false,
    editing: false
  }),
  methods: {
    edit: function() {
      this.editing = !this.editing
    },
    save: function() {
      this.processing = true
      this.$store.dispatch('updateDeliveryService', this.deliveryMethodInstance).finally(() => {
        this.processing = false
      })
    }
  },
  computed: {
    deliveryMethod: function() {
      return this.$store.getters.getDeliveryMethod(this.deliveryMethodInstance.namespace)
    },
    deliveryMethodState: {
      get: function() {
        return this.deliveryMethodInstance.enabled
      },
      set: function(newValue) {
        this.processing = true
        let deliveryMethodInstance = JSON.parse(JSON.stringify(this.deliveryMethodInstance))
        deliveryMethodInstance.enabled = Boolean(newValue)
        this.$store.dispatch('updateDeliveryService', deliveryMethodInstance).finally(() => {
          this.processing = false
        })
      }
    }
  }
}
</script>
