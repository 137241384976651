<template>
  <div>
    <div v-if="deliveryMethodInstances === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <v-card
      outlined
      v-if="deliveryMethodInstances !== null"
    >
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle> {{ subTitle }} </v-card-subtitle>
      <v-list v-if="deliveryMethodInstances.length > 0">
        <template v-for="(deliveryMethodInstance, index) in deliveryMethodInstances">
          <DeliveryMethodInstanceListItem
            :delivery-method-instance="deliveryMethodInstance"
            :key="deliveryMethodInstance.uuid"
          />
          <v-divider
            inset
            :key="index"
            v-if="index < deliveryMethodInstances.length - 1"
          />
        </template>
      </v-list>
      <v-card-text v-else>
        {{ $t('nothingToShow') }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

  import DeliveryMethodInstanceListItem from "./DeliveryMethodInstanceListItem"

  export default {
    name: 'PaymentList',
    props: {
      deliveryMethods: {
        type: Array, 
        default: () => []
      },
      deliveryMethodInstances: {
        type: Array, 
        default: () => []
      },
      title: {
        type: String, 
        default: ''
      }, 
      subTitle: {
        type: String, 
        default: ''
      }
    },
    components: {
      DeliveryMethodInstanceListItem
    },
    data: () => ({
      startIndex: 0,
      isLoadingMore: false,
    }),
    methods: {
      loadMore: function() {
        this.isLoadingMore = true
        this.length = this.length + this.step
      }
    }
  }
</script>
